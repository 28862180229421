<template>
  <div v-if="isLoaded" class="home-profile" @reRender="getData">
    <div class="home-top">
      <div class="home-top-profile">
        <div class="profile-pic">
          <img
              v-if="profilePic"
              :src="profilePic"
              alt="ProfilePic"
              class="round-image"
          />
          <img v-else class="round-image" src="@/images/default-avatar.png"/>
        </div>
        <div class="home-top-info">
          <h2>{{ profile.firstName + " " + profile.lastName }}</h2>
          <h3 v-if="profile.company!='default'">{{ company.name }}</h3>
          <h3 v-else>{{ profile.major }}</h3>
        </div>
      </div>
      <a
          v-if="resume"
          :href="resume"
          class="home-top-icon"
          target="_blank"
      >
        <ion-icon class="big-icon" name="document-text-outline"></ion-icon>
      </a>

      <a
          v-else
          :href="resume"
          class="home-top-icon"
          target="_blank"
          @click="noResumeAlert"
      >
        <ion-icon class="big-icon" name="document-text-outline"></ion-icon>
      </a>

      <div class="home-top-icon" @click="$emit('edit-profile')">
        <ion-icon class="big-icon" name="pencil-outline"></ion-icon>
      </div>
    </div>

    <hr class="good-line-top"/>

    <div :class="{'collapse-content-container':true, 'collapse-content-containerFolded':isCollapsed}">
      <div class="profile-detail-content">
        <div class="about">
          <h3>About</h3>
          <h4 v-if="profile.about==null" class="wrap">Hi, this is {{ profile.firstName + " " + profile.lastName }}. Nice
            to meet you!</h4>
          <h4 class="wrap">{{ profile.about }}</h4>
        </div>

        <div class="exp-title">
          <h3>Experience</h3>
          <h4 v-if="profile.experience.length == 0">
            You have not added any experience yet.
          </h4>
        </div>

        <ul class="experience">
          <li v-for="exp in profile.experience" :key="exp.id" class="my-exp">
            <div class="my-exp-top">
              <h2>{{ exp.title }}</h2>
              <h4 v-if="exp.currentInRole">
                {{ modifyDate(exp.startDate.toDate()) }} - Present
              </h4>
              <h4 v-if="!exp.currentInRole">
                {{ modifyDate(exp.startDate.toDate()) }} -
                {{ modifyDate(exp.endDate.toDate()) }}
              </h4>
            </div>
            <h4 style="padding-bottom: 1vh; font-weight: bold">
              {{ exp.company }}
            </h4>
            <h4 class="wrap">{{ exp.description }}</h4>
          </li>
        </ul>
      </div>
    </div>


    <hr :class="{'good-line-bot':true, 'line-invisible':isCollapsed}"/>

    <div class="expand-collapse" @click="isCollapsed = !isCollapsed">
      <div>
        <ion-icon
            v-show="!isCollapsed"
            class="up-down big-icon"
            name="chevron-up-outline"
        ></ion-icon>
        <ion-icon
            v-show="isCollapsed"
            class="up-down big-icon"
            name="chevron-down-outline"
        ></ion-icon>
      </div>
      <h4 v-show="!isCollapsed" class="more-less">
        Show less profile information
      </h4>
      <h4 v-show="isCollapsed" class="more-less">
        Show more profile information
      </h4>
    </div>
  </div>
</template>

<script>
import getProfile from "@/composables/Profile/getProfile";
import getUser from "@/composables/getUser";
import getUrlAndName from "@/composables/Storage/getUrlAndName";
import getCompany from "@/composables/WishList/getCompany";
import {onMounted, ref, watchEffect, watch} from "vue";
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  props: ["reRender"],
  emits: ["edit-profile", "homeProfileLoaded"],
  async setup(props, {emit}) {
    const {user} = getUser();
    const isCollapsed = ref(false);
    const profilePic = ref(false);
    const resume = ref(null);
    const isLoaded = ref(false);
    const company = ref(null)
    const profile = ref(null);

    const getData = async () => {
      await getProfile(profile, user.value.uid);
      await getCompany(company, profile.value.company)
      await getUrlAndName(profilePic, null, user.value.uid, "Images");
      await getUrlAndName(resume, null, user.value.uid, "Resumes");
    };

    watch(profile, async () => {
      await getCompany(company, profile.value.company)
    })

    onMounted(async () => {
      await getData();
      isLoaded.value = true;
      emit("homeProfileLoaded");
    });

    // console.log(profile.value);
    // console.log(profilePic.value);
    // console.log(resume.value);

    const noResumeAlert = () => {
      createToast({
        title: "No resume!",
        description: 'Please upload your resume in Account Settings.'
      }, {
        type: "warning"
      })
    }

    const modifyDate = (dateHold) => {
      return (
          dateHold.getMonth() +
          1 +
          "/" +
          dateHold.getDate() +
          "/" +
          dateHold.getFullYear()
      );
    };

    watchEffect(async () => {
      if (!props.reRender) {
        // console.log("watchEffect " + props.reRender);
        setTimeout(async function () {
          await getData();
        }, 300);
      }
    });

    return {
      isLoaded,
      profile,
      company,
      profilePic,
      resume,
      isCollapsed,
      noResumeAlert,
      getData,
      modifyDate,
    };
  },
};
</script>

<style scoped>
.home-profile {
  background-color: #FFFFFF;
  border-radius: 3vh;
  padding: 3vh;
  margin-bottom: 5vh;
  align-items: center;
  animation: fadein 1s;
}

.home-top {
  display: flex;
  flex-wrap: wrap;
}

.home-top-profile {
  flex: 1 1 auto;
  display: flex;
}

.profile-pic {
  padding-right: 2vh;
  flex: 0 0 auto;
  align-self: center;
}

.profile-pic img {
  height: 8vh;
  width: 8vh;
}

.home-top-info {
  flex: 1 1 auto;
  line-height: 3vh;
  align-self: center;
}

.home-top-info h2 {
  margin-bottom: 1vh;
  font-size: var(--subheader);
  font-weight: bold;
}

.home-top-info h3 {
  font-size: var(--text);
  font-weight: 100;
}

.home-top-icon {
  color: black;
  flex: 0 0 70px;
  align-self: center;
}

.home-top-icon:hover {
  color: #cca2d8;
  cursor: pointer;
  transition: color 0.15s ease;
}

.good-line-top {
  margin-top: 2vh;
}

.good-line-bot {
  margin-bottom: 2vh;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.line-invisible {
  opacity: 0;
  transition: opacity 0.25s ease;
}

.collapse-content-container {
  max-height: 100vh;
  transform-origin: top;
  transition: all 1s ease;
  overflow: hidden;
}

.collapse-content-containerFolded {
  max-height: 0vh;
  transform-origin: top;
  transition: all 0.5s ease;
  overflow: hidden;
}

.profile-detail-content {
  padding: 2vh 0;
}

.about {
  padding-bottom: 1vh;
}

.about h3 {
  font-size: var(--subheader);
  line-height: 5vh;
}

.about h4 {
  font-size: var(--text);
  font-weight: 50;
}

.exp-title h3 {
  font-size: var(--subheader);
  line-height: 5vh;
}

.exp-title h4 {
  font-size: var(--text);
  font-weight: 50;
}

.my-exp h2 {
  font-size: var(--subheader);
  line-height: 5vh;
}

.my-exp h4 {
  font-size: var(--text);
  font-weight: 50;
}

.expand-collapse {
  display: flex;
  overflow: hidden;
  color: grey;
}

.expand-collapse:hover {
  color: black;
  cursor: pointer;
}

.expand-collapse div {
  flex: 0 1 6%;
  align-self: center;
  transition: all 0.25s ease;
}

.experience {
  list-style: none;
}

.expand-collapse h4 {
  font-size: var(--text);
  font-weight: 75;
  flex: 1 0 auto;
  align-self: center;
  transition: all 0.25s ease;
}

.up-down {
  animation: fadein 1s;
}

.more-less {
  animation: fadein 1s;
}

.my-exp-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.my-exp-top h2 {
  flex: 0 1 70%;
}

.my-exp-top h4 {
  flex: 1 0 auto;
  font-weight: bold;
}
</style>
